@tailwind base;


@layer base {
    .no-tailwindcss-base h1,
    .no-tailwindcss-base h2,
    .no-tailwindcss-base h3,
    .no-tailwindcss-base h4,
    .no-tailwindcss-base h5,
    .no-tailwindcss-base h6,
    .no-tailwindcss-base p {
        font-size: revert;
        font-weight: revert;
        margin: revert;
        padding: revert;
    }
     
    .no-tailwindcss-base ol,
    .no-tailwindcss-base ul {
        list-style: revert;
        margin: revert;
        padding: revert;
    }

    
}

@tailwind components;
@tailwind utilities;

.sidebar-stl {
    box-shadow: '1px 2px 9px #F4AAB9';
    background: #80aac2eb;
    
    font-size: 18px;
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #ebebeb;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 2px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aeaeae;
}

